import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const ContactForm = ({ onClose = () => {} }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Show loading toast
    const toastId = toast.loading("Sending your message...");

    try {
      //console.log("API URL:", process.env.REACT_APP_API_URL);
      await axios.post(`${process.env.REACT_APP_API_URL}/api/send-email`, formData);

      // Update toast to success
      toast.update(toastId, { render: "Email sent successfully!", type: "success", isLoading: false, autoClose: 3000 });
      
      // Delay closing the drawer by 1 second to allow the toast to show first
      setTimeout(onClose, 1000);
    } catch (error) {
      toast.update(toastId, { render: "Failed to send email. Please try again later.", type: "error", isLoading: false, autoClose: 3000 });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        Color: 'linear-gradient(135deg, #e6f7fb, #cceeff)', // Light blue gradient for a fresh, clean feel
      }}
    >
      <Typography variant="h7" sx={{ marginBottom: '20px', textAlign: 'center' }}>
        Please share your name, phone, and your cleaning needs below and we will contact you ASAP!
      </Typography>
      
      <TextField
        fullWidth
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
        sx={{
          marginTop: '20px',
          marginBottom: '15px',
          '& .MuiInputBase-root': {
            transition: 'box-shadow 0.3s ease', // Transition for the glow effect
          },
          '& .MuiInputBase-root.Mui-focused': {
            boxShadow: '0 0 10px rgba(25, 118, 210, 0.5)', // Blue glow on focus
          },
        }}
      />
      <TextField
        fullWidth
        label="Email"
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        required
        sx={{
          marginBottom: '15px',
          '& .MuiInputBase-root': {
            transition: 'box-shadow 0.3s ease',
          },
          '& .MuiInputBase-root.Mui-focused': {
            boxShadow: '0 0 10px rgba(25, 118, 210, 0.5)',
          },
        }}
      />
      <TextField
        fullWidth
        label="Subject"
        name="subject"
        value={formData.subject}
        onChange={handleChange}
        required
        sx={{
          marginBottom: '15px',
          '& .MuiInputBase-root': {
            transition: 'box-shadow 0.3s ease',
          },
          '& .MuiInputBase-root.Mui-focused': {
            boxShadow: '0 0 10px rgba(25, 118, 210, 0.5)',
          },
        }}
      />
      <TextField
        fullWidth
        label="Message"
        name="message"
        value={formData.message}
        onChange={handleChange}
        required
        multiline
        rows={4}
        sx={{
          marginBottom: '20px',
          '& .MuiInputBase-root': {
            transition: 'box-shadow 0.3s ease',
          },
          '& .MuiInputBase-root.Mui-focused': {
            boxShadow: '0 0 10px rgba(25, 118, 210, 0.5)',
          },
        }}
      />

      <Box textAlign="center">
        <Button 
          variant="contained" 
          color="primary" 
          type="submit"
          sx={{
            backgroundColor: '#1976d2',
            '&:hover': { backgroundColor: '#005bb5' },
            padding: '10px 20px'
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default ContactForm;
