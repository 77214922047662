import React from 'react';
import { Box, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const Footer = () => {
  return (
    <footer style={{
      background: 'linear-gradient(135deg, #006a71, #1f2f86)',
      padding: '10px 0',
      textAlign: 'center',
      width: '100%',
      boxShadow: '0 -4px 6px rgba(0, 0, 0, 0.1)',
      position: 'relative',
    }}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'center', 
        alignItems: 'center',
        gap: { xs: '5px', sm: '10px' },
      }}>
                <Box sx={{ display: 'flex', alignItems: 'center', color: 'white' }}>
          <PhoneIcon sx={{ fontSize: '1.1rem', marginRight: '5px', marginLeft: '10px' }} />
          <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' }, color: 'white', marginRight: '20px' }}>
            (541) 606-1706
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', color: 'white', marginLeft: '20px' }}>
          <EmailIcon sx={{ fontSize: '1.1rem', marginRight: '5px' }} />
          <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' }, color: 'white' }}>
            <a href="mailto:lowenbeholdcleaning@gmail.com" style={{ color: '#a0d8e7', textDecoration: 'none' }}>
              lowenbeholdcleaning@gmail.com
            </a>
          </Typography>
        </Box>
      </Box>

      <Typography variant="body2" sx={{ color: '#dfe8f0', fontSize: { xs: '0.7rem', sm: '0.7rem' }, marginTop: { xs: '5px', sm: '10px' } }}>
        © Lowen Behold Cleaning Services 2024
      </Typography>
    </footer>
  );
};

export default Footer;
