import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box, IconButton, Drawer, Typography, Menu, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../logo.png';
import ContactForm from './ContactForm';
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const handleFormSubmit = () => {
    setIsDrawerOpen(false);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      <AppBar 
        position="static" 
        sx={{
          background: 'linear-gradient(135deg, #006a71, #1f2f86)',
          padding: '5px 10px',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'auto',
          }}
        >
          {isMobile ? (
            <>
              <Box sx={{ textAlign: 'center' }}>
                <Link to="/">
                  <img src={logo} alt="Lowen Behold Cleaning Logo" style={{ width: '80px', height: '80px' }} />
                </Link>
              </Box>

              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
                sx={{ color: '#ffffff' }}
              >
                <MenuIcon />
              </IconButton>
            </>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <Button
                component={Link}
                to="/"
                variant="text"
                sx={{
                  color: location.pathname === '/' ? '#ffffff' : '#9fbcdf',
                  fontWeight: location.pathname === '/' ? 'bold' : 'normal',
                  borderBottom: location.pathname === '/' ? '2px solid #ffffff' : 'none',
                  fontSize: '1.2rem',  // Larger font size
                  textTransform: 'none',
                  padding: '4px 8px',
                  '&:hover': {
                    color: '#ffffff',
                    borderBottom: '2px solid #ffffff',
                  },
                }}
              >
                Home
              </Button>
              <Button
                component={Link}
                to="/services"
                variant="text"
                sx={{
                  color: location.pathname === '/services' ? '#ffffff' : '#9fbcdf',
                  fontWeight: location.pathname === '/services' ? 'bold' : 'normal',
                  borderBottom: location.pathname === '/services' ? '2px solid #ffffff' : 'none',
                  fontSize: '1.2rem',  // Larger font size
                  textTransform: 'none',
                  padding: '4px 8px',
                  '&:hover': {
                    color: '#ffffff',
                    borderBottom: '2px solid #ffffff',
                  },
                }}
              >
                Services
              </Button>

              <Box sx={{ textAlign: 'center' }}>
                  <img src={logo} alt="Lowen Behold Cleaning Logo" style={{ width: '120px', height: '120px' }} />
              </Box>

              <Button
                component={Link}
                to="/about"
                variant="text"
                sx={{
                  color: location.pathname === '/about' ? '#ffffff' : '#9fbcdf',
                  fontWeight: location.pathname === '/about' ? 'bold' : 'normal',
                  borderBottom: location.pathname === '/about' ? '2px solid #ffffff' : 'none',
                  fontSize: '1.2rem',  // Larger font size
                  textTransform: 'none',
                  padding: '4px 8px',
                  '&:hover': {
                    color: '#ffffff',
                    borderBottom: '2px solid #ffffff',
                  },
                }}
              >
                About
              </Button>
              <Button
                variant="text"
                sx={{
                  color: location.pathname === '/contact' ? '#ffffff' : '#9fbcdf',
                  fontWeight: location.pathname === '/contact' ? 'bold' : 'normal',
                  borderBottom: location.pathname === '/contact' ? '2px solid #ffffff' : 'none',
                  fontSize: '1.2rem',  // Larger font size
                  textTransform: 'none',
                  padding: '4px 8px',
                  '&:hover': {
                    color: '#ffffff',
                    borderBottom: '2px solid #ffffff',
                  },
                }}
                onClick={toggleDrawer(true)}
              >
                Contact Us
              </Button>
            </Box>
          )}
        </Toolbar>

        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: '#1f2f86',
              color: '#ffffff',
            },
          }}
        >
          <MenuItem component={Link} to="/" onClick={handleMenuClose}>
            Home
          </MenuItem>
          <MenuItem component={Link} to="/services" onClick={handleMenuClose}>
            Services
          </MenuItem>
          <MenuItem component={Link} to="/about" onClick={handleMenuClose}>
            About
          </MenuItem>
          <MenuItem onClick={() => { handleMenuClose(); toggleDrawer(true)(); }}>
            Contact Us
          </MenuItem>
        </Menu>

        <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            sx={{
              '& .MuiDrawer-paper': {
                width: { xs: '100%', sm: '400px' },
                padding: { xs: '15px', sm: '20px' },
                background: 'white', 
                color: '#004466', // Darker text for readability
              },
            }}
          >
            <Box sx={{ width: '100%', padding: '20px', textAlign: 'center' }} role="presentation">
              <Box sx={{ textAlign: 'right', marginBottom: '10px' }}>
                <IconButton onClick={toggleDrawer(false)} sx={{ color: '#004466' }}> {/* Updated icon color */}
                  <CloseIcon />
                </IconButton>
              </Box>

              <Typography variant="h5" sx={{ marginBottom: '20px', color: '#004466' }}> {/* Updated text color */}
                Contact Us
              </Typography>
              <ContactForm onClose={handleFormSubmit} />
            </Box>
          </Drawer>

      </AppBar>
    </>
  );
};

export default Header;
