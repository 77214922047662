import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import MovingIcon from '@mui/icons-material/Moving';
import SavingsIcon from '@mui/icons-material/Savings';
import OfficeIcon from '@mui/icons-material/Work';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import AccessAlarmSharpIcon from '@mui/icons-material/AccessAlarmSharp';

const Services = () => {
  return (
    <Box
      sx={{
        maxWidth: '1200px',
        margin: '0 auto',
        padding: { xs: '20px 10px', sm: '40px 20px' },
        background: 'white', 
        borderRadius: '10px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        color: '#333', // Darker text color for readability
      }}
    >
      <Typography 
        variant="h4" 
        sx={{ 
          fontWeight: 'bold', 
          textAlign: 'center', 
          color: '#004466', 
          fontSize: { xs: '1.5rem', sm: '2rem' } 
        }}
      >
        Lowen Behold Residential & Commercial Cleaning Services
      </Typography>

      <Grid container spacing={4}>
        {/* Residential Cleaning Card */}
        <Grid item xs={12} md={6}>
          <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1px' }}>
                <HomeIcon sx={{ fontSize: '40px', color: '#0096c7', marginRight: '10px' }} />
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#004466', fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
                  Residential Cleaning
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ marginBottom: '15px', color: '#333' }}>
                We offer premium residential cleaning services to keep your home clean and healthy. Our services ensure your home remains spotless and comfortable.
              </Typography>
              {/* Residential Cleaning Categories */}
              <Box sx={{ marginBottom: '10px', paddingLeft: '20px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <SavingsIcon sx={{ fontSize: '24px', color: '#0096c7', marginRight: '10px' }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#004466', fontSize: '1rem' }}>
                    Basic Residential Service
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ marginBottom: '10px', color: '#333', fontSize: '0.875rem' }}>
                  We offer weekly or bi-weekly cleaning scheduled for a time based on your schedule. Affordable cleaning services have never been more convenient!
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '10px', paddingLeft: '20px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <CleaningServicesIcon sx={{ fontSize: '24px', color: '#0096c7', marginRight: '10px' }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#004466', fontSize: '1rem' }}>
                    Deep Cleaning Services
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ marginBottom: '10px', color: '#333', fontSize: '0.875rem' }}>
                  From all those nooks & crannies to light fixtures, baseboards, refrigerators, and ovens...We clean every inch of your home!
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '1px', paddingLeft: '20px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <MovingIcon sx={{ fontSize: '24px', color: '#0096c7', marginRight: '10px' }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#004466', fontSize: '1rem' }}>
                    Moving In/Out Cleaning
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ color: '#333', fontSize: '0.875rem' }}>
                  Whether you're moving in or out, let us handle the cleaning! We ensure that your home is spotless and ready for the next step.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Commercial Cleaning Card */}
        <Grid item xs={12} md={6}>
          <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1px' }}>
                <BusinessIcon sx={{ fontSize: '40px', color: '#0096c7', marginRight: '10px' }} />
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#004466', fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
                  Commercial Cleaning
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ marginBottom: '15px', color: '#333' }}>
                We provide services tailored to your business needs. With our attention to detail, your office or business receives the cleaning it deserves!
              </Typography>
              {/* Commercial Cleaning Categories */}
              <Box sx={{ marginBottom: '10px', paddingLeft: '20px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <OfficeIcon sx={{ fontSize: '24px', color: '#0096c7', marginRight: '10px' }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#004466', fontSize: '1rem' }}>
                    Office Cleaning
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ marginBottom: '10px', color: '#333', fontSize: '0.875rem' }}>
                  Regular office cleaning to maintain a clean and productive workspace. We offer services during or after hours to avoid disruption.
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '10px', paddingLeft: '20px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <HolidayVillageIcon sx={{ fontSize: '24px', color: '#0096c7', marginRight: '10px' }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#004466', fontSize: '1rem' }}>
                  Airbnb & Short-Term Rental Cleaning
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ marginBottom: '10px', color: '#333', fontSize: '0.875rem' }}>
                Ensure every guest experiences a spotless, welcoming environment. We will prep, clean, and reset your space to perfection.
               </Typography>
              </Box>
              <Box sx={{ marginBottom: '1px', paddingLeft: '20px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <AccessAlarmSharpIcon sx={{ fontSize: '24px', color: '#0096c7', marginRight: '10px' }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#004466', fontSize: '1rem' }}>
                  Flexible After-Hours Cleaning
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ color: '#333', fontSize: '0.875rem' }}>
                Maintain your workspace without disrupting business hours. We offer cleaning services fit your business schedule.
               </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Custom Cleaning Needs Card */}
        <Grid item xs={12}> 
          <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
            <CardContent>
              <Box 
                sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center', 
                  textAlign: 'center'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#004466', fontSize: { xs: '1.25rem', sm: '1.5rem' }}}>
                    Custom Cleaning Needs? Contact us for a free quote!<br></br>We proudly offer discounts for Military, Veterans and Seniors. 
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ color: '#333', fontSize: { xs: '0.9rem', sm: '1rem' }, marginTop: '20px' }}>
                    <i>* Lowen Behold Cleaning is not responsible for damages to property, or unreturned security/cleaning deposits.</i>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Services;
