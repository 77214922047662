
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import './App.css'; // Assuming you'll add the CSS for transitions here
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <SwitchTransition>
      <CSSTransition
        key={location.key}
        classNames="fade"
        timeout={100} // Transition duration
      >
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </CSSTransition>
    </SwitchTransition>
  );
}

function App() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>
      <Router>
        <Header />

        {/* Main content area with flex-grow to push footer to bottom */}
        <main style={{ flexGrow: 1, padding: '20px' }}>
          <AnimatedRoutes />
        </main>
        
        <Footer />
      </Router>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar /> {/* Make sure this is at the top level */}
    </div>
  );
}

export default App;
