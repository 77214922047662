import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import ContactForm from '../components/ContactForm';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const About = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: { xs: '20px', sm: '40px' },
        maxWidth: '1200px',
        margin: '0 auto',
        padding: { xs: '10px', sm: '20px' },
        background: 'white', 
        borderRadius: '10px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        color: '#333', // Darker text color for contrast
      }}
    >
      {/* Text Content */}
      <Box sx={{ flex: 1, padding: { xs: '15px', sm: '20px' } }}>
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 'bold', 
            marginBottom: '10px', 
            color: '#004466', 
            fontSize: { xs: '1.5rem', sm: '2rem' } 
          }}
        >
          We Clean Exceptionally...
        </Typography>

        <Typography 
          variant="h5" 
          sx={{ 
            marginBottom: '10px', 
            color: '#0077a8', 
            fontSize: { xs: '1.25rem', sm: '1.5rem' } 
          }}
        >
          So <i>You</i> Don't Have To!
        </Typography>

        <Typography 
          variant="body1" 
          sx={{ 
            marginBottom: '15px', 
            color: '#333', 
            fontSize: { xs: '1rem', sm: '1.125rem' } 
          }}
        >
          Serving the greater Eugene/Springfield area, <strong>Lowen Behold Cleaning Services</strong> will meet or exceed your standards!
        </Typography>

        <Typography 
          variant="h6" 
          sx={{ 
            marginBottom: '10px', 
            fontWeight: 'bold', 
            color: '#004466', 
            fontSize: { xs: '1.125rem', sm: '1.25rem' } 
          }}
        >
          We are a local, family-owned business focused on:
        </Typography>

        {/* Bullet Points */}
        <List sx={{ textAlign: 'left', marginBottom: '20px', color: '#333' }}>
          {[
            'Customer Satisfaction',
            'Attention to Detail',
            'Quality Products',
            'Personalized Service',
            'Competitive Rates',
            'Highest Level of Quality',
          ].map((text, index) => (
            <ListItem key={index} disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" sx={{ color: '#0096c7' }} />
              </ListItemIcon>
              <ListItemText primary={text} sx={{ fontSize: { xs: '0.9rem', sm: '1rem' }, color: '#333' }} />
            </ListItem>
          ))}
        </List>

        <Typography 
          variant="body1" 
          sx={{ 
            marginBottom: '20px', 
            color: '#333', 
            fontSize: { xs: '1rem', sm: '1.125rem' } 
          }}
        >
          We Get the Job Done Properly... <strong>The First Time!</strong>
        </Typography>

        <Typography 
          variant="h6" 
          sx={{ 
            color: '#0096c7', 
            fontWeight: 'bold', 
            fontSize: { xs: '1rem', sm: '1.125rem' } 
          }}
        >
          Contact Us Today For a Free Estimate!
        </Typography>
      </Box>

      {/* Contact Form */}
      <Box
        sx={{
          flex: 1,
          padding: { xs: '15px', sm: '20px' },
          color: 'black',
          backgroundColor: 'transparent',
          maxWidth: { xs: '100%', sm: '500px' }
        }}
      >
        <ContactForm />
      </Box>

      {/* Toast Container for Notifications */}
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
    </Box>
  );
};

export default About;
